body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#app-menu-selected {
  background: #a30000;
  color: #ffffff;
  padding-left: 12px;
  display: flex;
  gap: 8px;
}

.white-text .dx-button-content, .white-text .dx-icon {
  color: #fff !important;
}

.dx-datagrid-header-panel {
  padding: 8px;
}

.dx-datagrid-header-panel .dx-toolbar {
  margin: 0;
}

.dx-treeview-item {
  cursor: pointer;
  padding: 5px 6px;
  min-height: 32px;
  border-radius: 8px 16px 16px 8px;
  margin-top: 4px;
  transition: padding .2s;
}

/* Barcode Editor */
.barcode-editor {
  font-family: Arial, Helvetica, sans-serif !important;
}

.barcode-editor h1 {
  margin: 0;
  font-size: 18px;
}

.barcode-editor button {
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 4px;
}

.barcode-editor button[data-active="true"] {
  background: #ccc;
}

.barcode-editor table {
  border-spacing: 0;
  width: 100%;
}

.barcode-editor td {
  margin: 0;
  padding: 6px 8px;
  border-bottom: 1px solid #888;
  vertical-align: top;
}

.barcode-editor tr:last-child td {
  border: 0;
}

.barcode-editor th {
  border-bottom: 1px solid #333;
}

.barcode-editor .row-alternation tr:nth-child(even) {
  background: #ddd;
}

.barcode-editor .row-alternation tr:last-child td {
  border-bottom: 0;
}

.barcode-editor hr {
  width: 100%;
  border: 0;
  outline: 1px solid #ccc;
}

.barcode-editor p.element {
  word-break: break-all;
  margin: 0;
}

.barcode-editor .element {
  position: relative;
}

.barcode-editor .element.box {
  padding: 8px;
}

.barcode-editor [data-selected='true'] {
  outline: 2px solid rgb(64, 155, 253) !important;
}
.barcode-editor .element:hover {
  outline: 1px solid rgb(64, 155, 253);
}
.drop-area.drag-hover {
  outline: 2px solid rgb(152, 0, 0);
}

#element-props-form .dx-popup-content {
  overflow: hidden auto;
}

.section-title {
  margin: 0;
  font-size: 18px;
}